import RoutesConfig from "./routes";


import "./App.css";
import "./comm/util/string-extension"

import {setCommonParameters} from "./comm/util/MyLocalStorage";
import {BurialPointService} from "./comm/service/burial_point_service";
import {useEffect, useState} from "react";
import UserAgent from "user-agent";
import {params, updateParams} from "./comm/http/http_request";
import {useVisitorData} from "@fingerprintjs/fingerprintjs-pro-react";
import {reportDeviceInfo} from "./comm/service/device_info_service";

var visitorId;

function App() {
    const {isLoading, error, data, getData} = useVisitorData(
        {extendedResult: true},
        {immediate: true}
    )
    setCommonParameters(window.location.href
        .replaceAll("??", "?")
        .replaceAll("&?", "&").split('?')[1])
    const [showSafariMessage, setShowSafariMessage] = useState(false);
    // init()
    //
    // //
    // // useEffect(() => {
    // //     // const userAgent = UserAgent.parse(navigator.userAgent);
    // //     // // const isSafari = userAgent.full.toLowerCase().includes('safari');
    // //     // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // //     // updateParams()
    // //     // if (!isSafari ) {
    // //     //     setShowSafariMessage(true);
    // //     // }
    // //     // getData()
    // //
    // // }, []);
    // // // isLoading 变为 true 后执行的逻辑
    // // useEffect(() => {
    // //     // console.log("generateFingerprint", isLoading, error, data)
    // //     if (data && visitorId === undefined) {
    // //         visitorId = data.visitorId
    // //         console.log('延时1秒后执行的操作,获取成功');
    // //         localStorage.setItem('generateFingerprint', visitorId);
    // //         reportDeviceInfo()
    // //     }
    // //     if (error != undefined && visitorId === undefined) {
    // //         visitorId = error.message
    // //         getData()
    // //         // localStorage.setItem('generateFingerprint', visitorId);
    // //         // reportDeviceInfo()
    // //     }
    // //
    // // }, [data, error]);
    // // useEffect(() => {
    // //     const delay = 3000; // 1秒（1000毫秒）
    // //     const delayedFunction = () => {
    // //         if (visitorId === undefined) {
    // //             reportDeviceInfo()
    // //             console.log('延时1秒后执行reportDeviceInfo');
    // //         }
    // //         console.log('延时1秒后执行的操作');
    // //     };
    // //     const timeoutId = setTimeout(delayedFunction, delay);
    // //
    // //     // 在组件卸载时清除定时器，避免内存泄漏
    // //     return () => clearTimeout(timeoutId);
    // // }, []); // useEffect 依赖项为空数组，表示只在组件挂载和卸载时执行
    return (
        <div>
            {showSafariMessage && (
                <div style={{
                    position: 'fixed',
                    zIndex: 9999,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    textAlign: 'center',
                    padding: 20,
                    fontSize: 24,
                }}>
                    Silakan buka halaman ini di browser Safari.
                </div>
            )}
            <div className="App">
                <RoutesConfig/>
            </div>
        </div>
    );
}

function init() {
    BurialPointService.init();
}

export default App;
