export default {
//-----config----------------------------------------------------------------
    'host': '',

//---h5 相关--------------------------------------------------------

    //-custom--------------------
    'mine': '/mine',     //我的
    'auth': '/auth',     //认证页面
    //--------------------------------------
    //菲律宾
    // 'errorUrl': '/Fla', //'/jointLogonFail',     //准入失败页面
    // 'loanDetailUrl': '/Flb', // '/loanDetailsV3',     //借款详情页
    // 'orderUrl': '/Flc', //'/loanDetailsV3',     //订单详情页面
    // 'confirmUrl': '/Fld', //'/confirmOfLoanV3',     //借款详情页
    // 'confirmNewUrl': '/Fle', //'/confirmUrl',     //后置确认详情页
    // 'confirmOfPeriod': '/Flf', //'/confirmOfLoanV3',     //借款详情页
    // 'repayUrl': '/Flff', //'/repaymentOfPeriod',     //还款页
    // 'customerService': '/Flg', //'/loanDetailsV3',     //订单详情页面
    // 'loanAgreement': '/Flh', //'/loanDetailsV3',     //借款协议
    // 'aboutUs': '/Fli', //'/loanDetailsV3',     //关于我们
    // 'privacyPolicy': '/Flj', //'/privacyPolicy',      //隐私协议
    // 'repayDelayUrl': '/Flk',  //展期
    // 'complaint': '/Fll',  //客服案件投诉入口
    // 'bindBank': '/Flk',  //绑卡页面
    //印尼
    'errorUrl' : '/science', //'/jointLogonFail',        //准入失败页面
    'loanDetailUrl' : '/incorporates', // '/loanDetailsV3',   //借款详情页
    'confirmUrl' : '/physics', //'/confirmOfLoanV3',    //确认借款页
    'ynRepay' :  '/engineering', //'/ynRepay',                  //还款详情
    'orderUrl' :  '/chemistry', //'/loanDetailsV3',         //订单详情页面
    'recommendList' :  '/paradigm', //'/recommendList',      //推荐产品列表页面
    'customerService' :  '/advance', //'/customerService', //客服
    'repayUrl' :  '/understanding', //'/repaymentOfPeriod',        //还款页面
    'repayDelayUrl' :  '/variety', //'/repaymentOfDelay',   //展期还款
    'ynRepayChoose' :  '/research', //'/ynRepayChoose',      //选择还款方式
    'loanAgreement' :  '/including', //'/loanAgreement',  //借款协议
    'privacyPolicy' :  '/important', //'/privacyPolicy',      //隐私协议
    'aboutUs' :  '/forensic',      //关于我们
    'complaint':'/failure',  //客服案件投诉入口
    'binDsardUrl' :  '/analysis',
    'complaintUrl' :  '/investigating', // complaint
    'productUrl' :  '/absorbing',  // apiDetails

    'logout': '/Logout',   //设置页

    'setting': '/many',   //设置页
    'main': '/long',     //首页
    'login': '/brought',     //登录
    'order': '/perfectly',          //订单
    'productDetail': '/frightened',    //产品详情
    'landingPage': '/pgLP',    //落地页
    'faceDetection': '/FaceDetection',    //人脸识别
    //--------值映射-认证项------------------------------

    'banner': "semi",
    'largeCard': "encyclopedia",
    'smallCard': "disambiguation",
    'productList': "redirects",
    "public": "molecule", // ocr认证
    "personal": "geometric", // 个人信息
    "job": "structure", // 个人信息
    "ext": "modelal", // 紧急联系人
    "bank": "molecule", // 绑卡
    "enum": "filling",
    "txt": "falling",
    "citySelect": "towards",
    "day": "day",
}