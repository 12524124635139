import styled from 'styled-components'

export const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0; 
  
  left: 0;
  z-index: 2; 
  .container-box {
    width: 18.75rem;
    height: 3rem;
    background: #FFFFFF;
    box-shadow: 0.1rem 0rem 1rem 0rem rgba(24, 57, 107, 0.05);
    border-radius: 1.2rem 1.2rem 0rem 0rem;
    font-size: 0.5rem;
    font-weight: 500;
    padding: 0 2rem;
    .tab-item {
        
      flex: 1;
      img {
        width: 1.6rem;
        height: 1.6rem;
      }

      .text-check {
        line-height: 0.7rem;
        color: #1A066F;
      }

      .text {
        line-height: 0.7rem;
        color: #646578;
      }
    }
  }


`