///状态管理工具。
import {getCommonParameters} from "../util/MyLocalStorage";
import AppConfig from "../../env.json"

export default {
    getUrl: (route) => getUrl(route),

    to: (rout1, {navigate}) => {
        const url = getUrl(rout1); // 使用箭头函数确保访问到正确的 this
        if (navigate) {
            navigate(url);
        } else {
            window.location.href = url;
        }
    },
    off: (rout1, {navigate}) => {
        let rout = rout1.replace("tunai://tunai.cepat", "");
        rout = rout.replace(AppConfig.appApi, "")
        let commPar = getCommonParameters();
        if (rout.indexOf('?') === -1) {
            commPar = `?${commPar}`
        } else {
            commPar = `&${commPar}`
        }
        if (navigate) {
            navigate(mergeUrls(rout, commPar), {replace: true});
        } else {
            window.location.href = mergeUrls(rout, commPar);
        }
    },
    back: () => {
        window.history.back();
    }
}

function getUrl(route) {
    let rout = route.replace("tunai://tunai.cepat", "");
    rout = rout.replace(AppConfig.appApi, "")
    let commPar = getCommonParameters();
    if (rout.indexOf('?') === -1) {
        commPar = `?${commPar}`
    } else {
        commPar = `&${commPar}`
    }
    return mergeUrls(rout, commPar)
}

function mergeUrls(baseUrl, additionalParams) {
    let us = baseUrl.split('?');
    if (us.length === 1) return baseUrl + "?" + additionalParams
    const params1 = new URLSearchParams(baseUrl);
    const additionalSearchParams = new URLSearchParams(additionalParams);
    for (const [key, value] of additionalSearchParams.entries()) {
        params1.set(key, value);
    }
    let par = decodeURIComponent(params1.toString());
    return par;
}
