import React, {useEffect, useState} from 'react'

import {Wrapper} from './order_style'
import {useNavigate} from 'react-router-dom';
// import Footer from "../../components/Footer";
import {httpPost, params, updateParams} from "../../comm/http/http_request";
import {PullToRefresh} from 'antd-mobile'
import orderArrow from '../../assets/ic/order_item_right.webp'
import orderEmpty from '../../assets/g/order_empty.png'
import Header from "../../components/Header";
import Lget from "../../comm/lget/lget";
import {isLogin} from "../../comm/util/MyLocalStorage";
import Comm_key_def from "../../comm/comm_key_def";

var tabIndex = 0;
export default function Order() {
    let tabNames = ['Menerapkan', 'Dilunasi'] //'Menerapkan',
    const [page, setPage] = useState(1)
    const [orderData, setOrderData] = useState([])
    const navigate = useNavigate();

    async function getData(loading) {
        let dataForm = {
            'pageNum': page, 'pageSize': 20, 'orderType': [ 6, 5][tabIndex], // 4全部 7进行中 6待还款 5已结清
        };

        let res = await httpPost('/v3/personal-center/order-list'.hx(), dataForm, loading);
        if (!res.success) return;
        let data = res.data;
        if (data == null) {
            setPage(1)
            setOrderData(null)
            return;
        }

        if (page === 1) {
            setOrderData(data['list'.hx()] ?? [])
        } else {
            setOrderData([...orderData, ...data['list'.hx()]])
        }
        return;
    }

    function onHideChange() {
        if (document.visibilityState === 'visible') {
            // 用户回到了页面
            getData('memuat...')
            // 进行相应的操作
        }
    }

    useEffect(() => {
        if (!isLogin()) {
            Lget.to(Comm_key_def.login, {navigate: navigate})
            return;
        }


        // 添加事件监听器
        document.addEventListener('visibilitychange', onHideChange);
        updateParams()
        tabIndex = parseInt(params['tabIndex']) || 0
        getData('memuat...')

        return () => {
            // 移除事件监听器
            document.removeEventListener('visibilitychange', onHideChange);
        };
    }, [])
    return (<Wrapper>
        <Header title="Memesan" isBask={false}/>

        <div className="flex-row-center">
            {tabNames.map((str, index) => {
                return (<div className={tabIndex === index ? 'tab-check' : 'tab-uncheck'} onClick={() => {
                    // setTabIndex(index)
                    tabIndex = index;
                    setPage(1)
                    getData('loading')
                }}>{str}</div>)
            })}
        </div>
        <div style={{flex: 1}}>
            {
                orderData.length === 0 ? <Empty/> : <PullToRefresh
                    onRefresh={async () => {
                        await getData()
                    }}
                >
                    {orderData.map((item, index) => {
                        return (<div className="order-item flex-column"
                                     style={{background: ['#F0F0FC', '#F0F0FC', '#F0F0FC'][index]}} onClick={() => {
                            Lget.to(item['loanDetailUrl'.hx()], {navigate: navigate})
                        }} key={index}>
                            <div className="flex-row-center" style={{width: '100%'}}>
                                <img className="product-img" src={item['productLogo'.hx()]}/>
                                <div className="product-name">{item['productName'.hx()]}</div>
                                <img src={orderArrow} className='arrow-right'/>
                            </div>
                            <div className="other-box" style={{marginTop: '0.3rem'}}>
                                {item['listData'.hx()].map((im, index) => {
                                    return (<div className="flex-row-center" key={index}>
                                        <div className="left">{im[['title'.hx()]]}</div>
                                        <div className="right">{im['txt'.hx()]}</div>
                                    </div>)
                                })}
                            </div>


                        </div>)
                    })}

                </PullToRefresh>
            }

        </div>
        {/*<Footer/>*/}
    </Wrapper>)
}

function Empty() {
    return <div className="empty">
        <img src={orderEmpty}/>
        <div className="text">Tidak ada</div>
    </div>
}
