import {HeaderWrapper} from "./style";
import {useNavigate} from "react-router-dom";
import back_whtie2 from "../../assets/i/back_whtie2.png"
import back_black from "../../assets/i/back_black.png"

export default function Header({title,isLight=true,background='#FFF',isBask=true}) {
    const navigate = useNavigate();
    return (<HeaderWrapper>
        <div className="head" style={{background: background, color: isLight ? '#000' : '#FFF'}}>
            <img src={isLight ? back_black : back_whtie2} onClick={() => {
                navigate(-1)
            }} className="back-img"   style={{opacity:isBask?1:0}}/>
            {title}</div>

    </HeaderWrapper>);

}
