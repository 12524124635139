import React from "react";
import {FooterWrapper} from "./style";
import {Link, useLocation} from "react-router-dom";
import comm_key_def from "../../comm/comm_key_def";
import homeYes from "../../assets/i/home_yes.png"
import homeNo from "../../assets/i/home_no.png"
import orderNo from "../../assets/tab/order_no.webp"
import orderYes from "../../assets/tab/order_yes.webp"
import mineYes from "../../assets/i/mine_yes.png"
import mineNo from "../../assets/i/mine_no.png"
import {isLogin} from "../../comm/util/MyLocalStorage";
import Comm_key_def from "../../comm/comm_key_def";
import Lget from "../../comm/lget/lget";

export default function Footer() {
    const {pathname} = useLocation();
    const routerNames = [{
        route: comm_key_def.main, name: 'Home', icons: [homeYes, homeNo]
    }, {
        //     route: comm_key_def.order, name: 'Order', icons: [orderYes, orderNo]
        // }, {
        route: comm_key_def.mine, name: 'Mine', icons: [mineYes, mineNo]
    }];
    return (<FooterWrapper>
        <div className="container-box flex-row">
            {routerNames.map((item) => {
                return tabItem(item)
            })}

        </div>
    </FooterWrapper>);

    function tabItem(item) {
        return <Link
            to={Lget.getUrl(isLogin() ? item.route : Comm_key_def.login)} replace={isLogin()}
            className="tab-item flex-column-center" key={item.route} style={{justifyContent: 'center'}}
        >
            <img src={pathname === item.route ? item.icons[0] : item.icons[1]}/>
            {/*<span className={pathname === item.route ? 'text-check' : 'text'}>{item.name}</span>*/}
        </Link>;
    }
}
