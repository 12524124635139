import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  min-height: 100%;

  .head {
    width: 18.75rem;
    height: 2.2rem;
    background: #1A066F;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 2.2rem;
  }

  .tab-check {
    height: 2.05rem;
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #642CD8;
    line-height: 2.05rem;
  }

  .tab-uncheck {
    height: 2.05rem;
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 2.05rem;
  }

  .empty {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    img {
      margin-top: 6.5rem;
      width: 5.65rem;
      height: 5.65rem;
    }

    .text {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #B8B8F2;
      line-height: 1.3rem;
    }
  }


  .order-item {
    margin-left: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    width: 16.75rem;
    background: #F9ECF5;
    border-radius: 0.6rem;

    .product-img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }

    .product-name {
      flex: 1;
      text-align: start;
      font-size: 0.7rem;
      font-weight: 600;
      color: #000000;
      line-height: 0.7rem;
    }

    .arrow-right {
      width: 1.25rem;
      height: 1.25rem;
    }

    .other-box {
      width: 100%;
      .left {
        text-align: start;
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 1.5rem;
      }

      .right {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        line-height: 1rem;
      }
    }

  }

`