import React, {useEffect, useState} from 'react'

import {httpPost} from '../../comm/http/http_request'
import {useNavigate} from 'react-router-dom';
import Footer from "../../components/Footer";
import {Wrapper} from './mine_style'
import head from '../../assets/i/head.png'
import arrowRight from '../../assets/ic/arrow_black.webp'
import {getUserInfo} from "../../comm/util/MyLocalStorage";
import Lget from "../../comm/lget/lget";

export default function Me() {
    let userInfo = getUserInfo()
    const navigate = useNavigate()

    // String ? name;
    const [mineMenus, setMineMenus] = useState([]);

    async function getMineMenus() {
        let res = await httpPost('/v3/personal-center/user-menu'.hx(), {}, 'memuat...');
        if (!res.success) return;
        setMineMenus(res.data['list'.hx()])
        // name=data?['userInfo']?['username'];
    }

    function onHideChange() {
        if (document.visibilityState === 'visible') {
            // 用户回到了页面
            getMineMenus()
            // 进行相应的操作
        }
    }


    useEffect(() => {
        // 添加事件监听器
        document.addEventListener('visibilitychange', onHideChange);
        getMineMenus()
        return () => {
            // 移除事件监听器
            document.removeEventListener('visibilitychange', onHideChange);
        };
    }, [])
    const hideMiddleDigits = (phoneNumber) => {

        const visibleDigits = phoneNumber.substring(0, 3) + '****' + phoneNumber.substring(7);
        return visibleDigits;
    }
    return (
        <Wrapper>
            <div className="head">Milikku</div>
            <div className="head-content flex-row-center">
                <img src={head}/>
                <div className="flex-column">
                    <div className="name">{hideMiddleDigits(userInfo['username'.hx()])}</div>
                    {/*<div className="phone">{hideMiddleDigits(userInfo['username'.hx()])}</div>*/}
                </div>
            </div>
            {
                mineMenus.map((item,index) => {
                    return <div className="menu-item flex-row-center" onClick={() => {
                        Lget.to(item['url'.hx()], {navigate: navigate})
                    }} key={index}>
                        <img src={item['icon'.hx()]}/>
                        <div className="text">{item['title'.hx()]}</div>
                        <img src={arrowRight} className="img-right"/>
                    </div>
                })
            }
            <Footer/>
        </Wrapper>
    )
}
