import React, {lazy, Suspense} from "react";
import {Routes, Route, Navigate,} from "react-router-dom";
import Home from "../pages/home/home"
import Order from "../pages/order/order"
import Mine from "../pages/mine/mine_index"
import comm_key_def from "../comm/comm_key_def";

const Login = lazy(() => import("../pages/login/login"));
const ProductDetail = lazy(() => import("../pages/product_detail/product_detail"));
const PrivacyAgreement = lazy(() => import("../pages/other/privacyAgreement"));
const AboutUs = lazy(() => import("../pages/other/AboutUs"));
const CustomerService = lazy(() => import("../pages/other/customerService"));
const Auth = lazy(() => import("../pages/auth/auth"));
const ConfirmLoan = lazy(() => import("../pages/confirmLoan/confirm_loan"));
const OrderDetail = lazy(() => import("../pages/orderDetail/order_detail"));
const RepaymentOfPeriod = lazy(() => import("../pages/repay/repaymentOfPeriod/repaymentOfPeriod"));
const RepaymentOfDelay = lazy(() => import("../pages/repay/repaymentOfPeriod/repaymentOfDelay"));
const YnRepay = lazy(() => import("../pages/repay/ynRepay/ynRepay"));
const YnRepayChoose = lazy(() => import("../pages/repay/ynRepay/ynRepayChoose"));
const Setting = lazy(() => import("../pages/mine/setting/setting"));
const Logout = lazy(() => import("../pages/mine/setting/logout"));
const FaceDetection = lazy(() => import("../components/FaceDetection/index"));
const LandingPage = lazy(() => import("../pages/other/landing_page"));
const LandingPage2 = lazy(() => import("../pages/other/landing_page2"));


export default function RoutesConfig() {
    return (
        <Suspense fallback={null}>
            <Routes>
                <Route path="/" element={<Navigate to={comm_key_def.order} replace={true}/>}></Route>
                <Route path={comm_key_def.main} element={<Home/>}></Route>
                <Route path={comm_key_def.order} element={<Order/>}></Route>
                <Route path={comm_key_def.mine} element={<Mine/>}></Route>
                <Route path={comm_key_def.login} element={<Login/>}></Route>
                <Route path={comm_key_def.productDetail} element={<ProductDetail/>}></Route>
                <Route path={comm_key_def.auth} element={<Auth/>}></Route>
                <Route path={comm_key_def.confirmUrl} element={<ConfirmLoan/>}></Route>
                {/*<Route path={comm_key_def.orderUrl} element={<OrderDetail/>}></Route>*/}
                <Route path={comm_key_def.loanDetailUrl} element={<OrderDetail/>}></Route>
                <Route path={comm_key_def.repayUrl} element={<RepaymentOfPeriod/>}></Route>
                <Route path={comm_key_def.repayDelayUrl} element={<RepaymentOfDelay/>}></Route>
                <Route path={comm_key_def.ynRepay} element={<YnRepay/>}></Route>
                <Route path={comm_key_def.ynRepayChoose} element={<YnRepayChoose/>}></Route>
                <Route path={comm_key_def.setting} element={<Setting/>}></Route>
                <Route path={comm_key_def.logout} element={<Logout/>}></Route>
                <Route path={comm_key_def.faceDetection} element={<FaceDetection/>}></Route>
                <Route path={comm_key_def.privacyPolicy} element={<PrivacyAgreement/>}></Route>
                <Route path={comm_key_def.aboutUs} element={<AboutUs/>}></Route>
                <Route path={comm_key_def.customerService} element={<CustomerService/>}></Route>
                <Route path="landing" element={<LandingPage/>}></Route>
                <Route path="landing2" element={<LandingPage2/>}></Route>

            </Routes>
        </Suspense>
    )
}
