import React, {useEffect, useRef, useState} from 'react'
import {httpPost, params, updateParams} from '../../comm/http/http_request'
import {Content} from './home_style'
import commKeyDef from "../../comm/comm_key_def";
import {isLogin} from "../../comm/util/MyLocalStorage";
import {PullToRefresh, List} from 'antd-mobile'
import large_card_bg from '../../assets/g/large_top_bg.png'
import large_card_top from '../../assets/g/large_card_top.png'
import home_loanstep from '../../assets/g/home_loanstep.png'
import small_card_top from '../../assets/g/small_card_top.png'
import small_right_img from '../../assets/g/small_right_img.png'
import tab1 from '../../assets/i/tab1.png'
import tab2 from '../../assets/i/tab2.png'
import tab3 from '../../assets/i/tab3.png'
import {useNavigate} from 'react-router-dom';
import Footer from "../../components/Footer";
import HomeLogic from "./home_logic";
import {reportAddressInfo, reportDeviceInfo} from "../../comm/service/device_info_service";
import Lget from "../../comm/lget/lget";
import {isEmpty} from "../../comm/util/string-extension";
// src/App.jsx
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react'

const tabImgs = [tab1, tab2, tab3]

var visitorId;


function Home() {
    const navigate = useNavigate();
    const [logic, setLogic] = useState(new HomeLogic({
        navigate: navigate
    }));
    const [indexData, setIndexData] = useState(null);
    const [showAddToHomeScreenDialog, setShowAddToHomeScreenDialog] = useState(false);


    // reportDeviceInfo()

    useEffect(() => {
        // 添加事件监听器
        document.addEventListener('visibilitychange', onHideChange);
        logic.getIndexData('memuat...').then((data) => {
            setIndexData(data)
        })
        reportAddressInfo()
        // isShowAddToHomeScreenDialog();

        return () => {
            // 移除事件监听器
            document.removeEventListener('visibilitychange', onHideChange);
        };
    }, [])

    // useEffect(() => {
    //     const delay = 1000; // 1秒（1000毫秒）
    //     const delayedFunction = () => {
    //         if (visitorId === undefined) {
    //             reportDeviceInfo()
    //             console.log('延时1秒后执行reportDeviceInfo');
    //         }
    //         console.log('延时1秒后执行的操作');
    //     };
    //     const timeoutId = setTimeout(delayedFunction, delay);
    //
    //     // 在组件卸载时清除定时器，避免内存泄漏
    //     return () => clearTimeout(timeoutId);
    // }, []); // useEffect 依赖项为空数组，表示只在组件挂载和卸载时执行


    // // isLoading 变为 true 后执行的逻辑
    // useEffect(() => {
    //     // console.log("generateFingerprint", isLoading, error, data)
    //     if (data && visitorId === undefined) {
    //         visitorId = data.visitorId
    //         console.log('延时1秒后执行的操作,获取成功');
    //
    //         localStorage.setItem('generateFingerprint', visitorId);
    //         reportDeviceInfo()
    //     }
    //     if (error != undefined) {
    //         visitorId = error.message
    //         localStorage.setItem('generateFingerprint', visitorId);
    //         reportDeviceInfo()
    //     }
    //
    // }, [data, error]);

    function isShowAddToHomeScreenDialog() {
        updateParams()
        var isToHomePopup = localStorage.getItem('isToHomePopup') || "false"
        // setShowAddToHomeScreenDialog(true)

        if (isLogin() && !isEmpty(params['source']) && isToHomePopup !== 'true') {
            setShowAddToHomeScreenDialog(true)
        }
    }

    function onHideChange() {
        if (document.visibilityState === 'visible') {
            // 用户回到了页面
            console.log('用户回到了页面');
            // 进行相应的操作
        }
    }

    let content;
    if (indexData == null) {
        content = <div> memuat...
        </div>
    } else if (logic.isLargeCard)
        content = LargeCard(logic.largeCardData, navigate, logic);
    else
        content = SmallCard(logic.smallCardData, logic.productList, navigate, logic);
    return (
        <Content>
            {/*<div className="head">Instant Credit</div>*/}
            <PullToRefresh
                onRefresh={async () => {
                    let data = await logic.getIndexData()
                    setIndexData(data)
                }}
            >
                {/*<p>VisitorId: {isLoading ? 'Loading...' : data?.visitorId}</p>*/}
                {content}
                <div style={{width: '0.5rem', height: '5rem'}}></div>

            </PullToRefresh>
            <Footer/>
            {/*{*/}
            {/*    showAddToHomeScreenDialog ?*/}
            {/*        <AddToHomeScreenDialog confirm={() => {*/}
            {/*            setShowAddToHomeScreenDialog(false)*/}
            {/*            localStorage.setItem('isToHomePopup', 'true')*/}
            {/*        }}/> : <div/>*/}
            {/*}*/}
        </Content>
    )
}

function LargeCard(data, navigate, logic) {
    return <div className="large-box flex-column-center">
        <div className="large-top-bg flex-column-center" style={{backgroundImage: `url(${large_card_bg})`}}
             onClick={() => logic.clickApplyCall(data, navigate)}>
        </div>
        <div className="large-card-bg flex-row" style={{backgroundImage: `url(${large_card_top})`}}
             onClick={() => logic.clickApplyCall(data, navigate)}>
            <div className="left-box flex-column-center">
                <img src={data['productLogo'.hx()]}/>
                <span>{data['productName'.hx()]}</span>
            </div>
            <div className="right-box flex-column">
                <span className="t1">{data['amountRangeDes'.hx()]}</span>
                <span className="t2">{data['amountRange'.hx()]}</span>
                <span className="t3">{'91 days /0.05%'}</span>
            </div>
        </div>
        <span className="btn-box" onClick={() => logic.clickApplyCall(data, navigate)}>{data['buttonText'.hx()]}</span>
        <TabOrderBox navigate={navigate} logic={logic}/>
        <img className="home_loanstep-box" src={home_loanstep} onClick={() => logic.clickApplyCall(data, navigate)}/>


    </div>;
}

function SmallCard(data, productList, navigate, logic) {
    return <div className="small-box flex-column-center"
    >

        <div className="small-top-bg flex-column-center" style={{backgroundImage: `url(${small_card_top})`}}
             onClick={() => logic.clickApplyCall(data, navigate)}>
        </div>
        <TabOrderBox navigate={navigate} logic={logic}/>

        <div className="small-card-bg flex-row" style={{backgroundImage: `url(${large_card_top})`}}
             onClick={() => logic.clickApplyCall(data, navigate)}>
            <div className="left-box flex-column-center">
                <img src={data['productLogo'.hx()]}/>
                <span>{data['productName'.hx()]}</span>
            </div>
            <div className="right-box flex-column">
                <span className="t1">{data['amountRangeDes'.hx()]}</span>
                <span className="t2">{data['amountRange'.hx()]}</span>
                <span className="t3">{'91 days /0.05%'}</span>
            </div>
        </div>
        <span className="btn-box" onClick={() => logic.clickApplyCall(data, navigate)}>{data['buttonText'.hx()]}</span>

        {
            productList.map((item, index) => {
                return (
                    <div className="product-item flex-row-center" onClick={() => logic.clickApplyCall(item, navigate)} key={index}>

                        <div className="left-box flex-column-center">
                            <img src={item['productLogo'.hx()]}/>
                            <div className="product-name">{item['productName'.hx()]}</div>
                        </div>
                        <div className="line"></div>

                        <div className="right-box flex-column">
                            <div className="amountRangeDes">{item['amountRangeDes'.hx()]}</div>
                            <div className="amount">{item['amountRange'.hx()]}</div>

                        </div>
                        <img src={small_right_img} className="right-img"/>
                    </div>)
            })
        }
    </div>;
}


function TabOrderBox({navigate, logic}) {
    const tabNames = ['Menerapkan', 'Menerapkan', 'Dilunasi']
    return <div className="order-tab-box flex-row">
        {
            tabNames.map((name, index) => {
                return <div className="item-box flex-column-center" key={index} onClick={() => {
                    if (!isLogin()) {
                        Lget.to(commKeyDef.login, {navigate: navigate})
                    } else {
                        Lget.to(commKeyDef.order + "?tabIndex=" + index, {navigate: navigate})
                    }
                }}>
                    <img src={tabImgs[index]}/>
                    <span>{name}</span>
                </div>
            })
        }
    </div>
}

export default Home

