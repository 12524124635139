import axios from 'axios';

export const Qs = () => {
    let sStr = window.location.href,
        params = {};
    let pattern = /(\w+)=([^&#]*)*/g;
    let arr = sStr.match(pattern);
    if (arr) {
        for (let i = 0; i < arr.length; i++) {
            let kv = arr[i].split('=');
            params[kv[0]] = kv[1];
        }
    }
    return params;
}

const params = Qs();
const $http = axios.create({
    timeout: 60000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'building': params.building || '',
        'gather': params.gather || '',
    }
});

$http.interceptors.response.use(response => {
    return response.data;
}, error => {
    return Promise.reject(error);
});

export {$http}

const $httpForm = axios.create({
    timeout: 60000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
        'swift': params.swift || '',
        'eyes': params.eyes || '',
    }
});

$httpForm.interceptors.response.use(response => {
    return response.data;
}, error => {
    return Promise.reject(error);
});

export {$httpForm}

export const toFix = num => {
    if (!isNaN(num)) {
        return (num / 100).toFixed(2);
    }
    return num;
}

const checkNum = n => {
    return n < 10 ? ("0" + n) : n;
}

export const DateF = (time, f) => {
    if (time) {
        let d = new Date(time);
        let YMd = d.getFullYear() + '-' + checkNum(d.getMonth() + 1) + "-" + checkNum(d.getDate());
        let Hms = checkNum(d.getHours()) + ":" + checkNum(d.getMinutes()) + ":" + checkNum(d.getSeconds());
        if (f === 'date') {
            return YMd;
        }
        return YMd + " " + Hms;
    }
    return time;
}

export const DateFun = (time, f) => {
    if (time) {
        let d = new Date(time);
        let YMd = d.getFullYear() + '-' + checkNum(d.getMonth() + 1) + "-" + checkNum(d.getDate());
        if (f === 'date') {
            return YMd;
        }
        return YMd;
    }
    return time;
};
//判断微信浏览器
export const isWX = () => {
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
        return true;
    } else {
        return false;
    }
};
// 生成 uuid
export const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};
export const uuid = () => {
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};
export const getCookie = (name) => {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) {
        return unescape(arr[2]);
    } else {
        return null;
    }
};
export const setCookie = (name, value) => {
    document.cookie = name + "=" + escape(value) + ";expires=Fri, 31 Dec 9999 23:59:59 GMT";
};

export const setTitle = (title) => {
    document.title = title;
    let u = navigator.userAgent,
        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
        // let i = document.createElement('iframe');
        // i.src = '/favicon.ico';
        // i.style.display = 'none';
        // i.onload = () => {
        //     setTimeout(() => {
        //         i.remove();
        //     }, 9);
        // };
        // document.body.appendChild(i);
    }
};

export const numFormat = (num) => {
    num = num.toString().split(".");  // 分隔小数点
    let arr=num[0].split("").reverse();  // 转换成字符数组并且倒序排列
    let res=[];
    for(let i = 0,len = arr.length; i < len; i++){
        if(i%3 === 0 && i !== 0){
            res.push(".");   // 添加分隔符
        }
        res.push(arr[i]);
    }
    res.reverse(); // 再次倒序成为正确的顺序
    if(num[1]){  // 如果有小数的话添加小数部分
        res=res.join("").concat("."+num[1]);
    }else{
        res=res.join("");
    }
    return res;
}