import {isEmpty} from "./string-extension";
import {DeviceInfoUtil} from "./device_info_util";

let _isLogin = false;
let token = "";

export function isFirst() {
    let isFist = localStorage.getItem('isFirst');
    return isFist === 'true'
}

export function setFirst() {
    localStorage.setItem('isFirst', 'true');

}


export function isLogin() {
    return _isLogin;
}

export function getToken() {
    let info = getUserInfo();
    if (info !== undefined && info !== null && info['sessionId'.hx()].length > 0) {
        return info['sessionId'.hx()];
    }
    return "";
}

export function setUserInfo(info) {
    if (!isEmpty(info) && info['sessionId'.hx()] !== undefined) {
        _isLogin = true;
        token = info['sessionId'.hx()];
        localStorage.setItem('loginUserInfo', JSON.stringify(info));
    } else {
        localStorage.setItem('loginUserInfo', null);

    }
}

export function getUserInfo() {//{username,sessionId,token}
    let info = JSON.parse(localStorage.getItem('loginUserInfo'));
    if (info !== undefined && info !== null && info['sessionId'.hx()].length > 0) {
        _isLogin = true;
    }
    return info;
}

function urlParamsToObject(urlParams) {
    const params = new URLSearchParams(urlParams);
    const obj = {};

    for (const [key, value] of params.entries()) {
        obj[key] = value;
    }
    return obj;
}

export function setCommonParameters(parameters) {
    const url = new urlParamsToObject(parameters);
    // if (!isEmpty(url['deviceId'.hx()])) {//有设备id
        const params = new URLSearchParams();
    for (const key in url) {
        if (url.hasOwnProperty(key)) {
            params.append(key, url[key]);
        }
    }
        const info=new DeviceInfoUtil()
        let obj = {}
        obj['deviceId'.hx()] = info.getDeviceId()
        obj['appVersion'.hx()] = info.getAppVersion()
        obj['deviceName'.hx()] = info.getDeviceName()
        obj['osVersion'.hx()] = info.getOsVersion()
        obj['gps_adid'.hx()] = info.getGpsAdid()


        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
        }
        localStorage.setItem('CommonParameters', params.toString());
    // }
}

export function getCommonParameters() {
    let info = localStorage.getItem('CommonParameters');
    return info;
}