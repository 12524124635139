import {httpPost, params, updateParams} from '../http/http_request'
import {DeviceInfoUtil} from "../util/device_info_util";
import {isEmpty} from "../util/string-extension";
import * as StringUtils from "../util/string-extension";
import axios from 'axios';
import tr from "react-datepicker";


class DeviceInfoService {

    async getDeviceInfo() {
        const info = new DeviceInfoUtil()
        if (isEmpty(info.getGpsAdid())) {
            await info.gpsAdidInit()
        }
        return {
            "common": this.getCommon(),
            "browser": this.getBrowser(),
            connection: this.getConnection(),
            "screenSize": this.getScreenSize(),
            "bluetooth": await this.bluetooth(),
            "battery": await this.getBattery(),
            "localStorage": window.localStorage,
            "sessionStorage": window.sessionStorage,
            "navigation": this.getNavigation(),
            "location": this.getLocation(),
            "history": this.getHistory(),

            // "window": window
        }
    }

    getHistory() {
        try {
            return {
                length: window.history.length,
                scrollRestoration: window.history.scrollRestoration,
                state: {
                    idx: window.history.state.idx,
                    key: window.history.state.key,
                    usr: window.history.state.usr,
                }
            }
        } catch (e) {
            return e.message
        }
    }

    getLocation() {
        try {
            return {
                "host": window.location?.host,
                "hostname": window.location?.hostname,
                "href": window.location?.href,
                "origin": window.location?.origin,
                "pathname": window.location?.pathname,
                "port": window.location?.port,
                "protocol": window.location?.protocol,
            }
        } catch (e) {
            return e.message
        }
    }

    getNavigation() {
        try {
            return {
                "id": window.navigation?.currentEntry.id,
                "index": window.navigation?.currentEntry.index,
                "key": window.navigation?.currentEntry.key,
                "sameDocument": window.navigation?.currentEntry.sameDocument,
                "url": window.navigation?.currentEntry.url,
                "canGoBack": window.navigation?.canGoBack,
                "canGoForward": window.navigation?.canGoForward,
            }

        } catch (e) {
            return e.message
        }

    }

    getScreenSize() {
        try {
            return {
                height: window.screen.height,
                width: window.screen.width,
                colorDepth: window.screen.colorDepth,
                availHeight: window.screen.availHeight,
                availLeft: window.screen.availLeft,
                availTop: window.screen.availTop,
                availWidth: window.screen.availWidth,
                isExtended: window.screen.isExtended,
                onchange: window.screen.onchange,
                pixelDepth: window.screen.pixelDepth,
            }
        } catch (e) {
            return e.message
        }
    }

    getConnection() {
        try {
            return {
                downlink: navigator.connection?.downlink,
                effectiveType:
                navigator.connection?.effectiveType,
                onchange: navigator.connection?.onchange,
                rtt:
                navigator.connection?.rtt,
                saveData:
                navigator.connection?.saveData
            }
        } catch (e) {
            return e.message
        }
    }

    getCommon() {
        const info = new DeviceInfoUtil()
        var adid = info.getGpsAdid()
        if (adid == undefined || adid.length == 0) {
            console.log('updateDeviceInfo', 'adid', 'empty', adid)
        } else {
            console.log('updateDeviceInfo', 'adid', adid)
        }
        try {
            return {
                isiOS: window.isiOS ? "1" : "0",
                deviceId: info.getDeviceId(),
                appVersion: info.getAppVersion(),
                deviceName: info.getDeviceName(),
                osVersion: info.getOsVersion(),
                gps_adid: info.getGpsAdid(),
            }
        } catch (e) {
            return e.message
        }
    }

    getBrowser() {
        try {
            return {
                userAgent: navigator.userAgent,
                appName: navigator.appName,
                appCodeName: navigator.appCodeName,
                appVersion: navigator.appVersion,
                clipboard: navigator.clipboard,
                cookieEnabled: navigator.cookieEnabled ? "1" : "0",
                credentials: navigator.credentials,
                deviceMemory: navigator.deviceMemory,
                doNotTrack: navigator.doNotTrack,
                gpu: navigator.gpu,
                hardwareConcurrency: navigator.hardwareConcurrency,
                hid: navigator.hid,
                ink: navigator.ink,
                Keyboard: navigator.Keyboard,
                language: navigator.language,
                languages: navigator.languages,
                locks: navigator.locks,
                login: navigator.login,
                managed: navigator.managed,
                maxTouchPoints: navigator.maxTouchPoints,
                mediaCapabilities: navigator.mediaCapabilities,
                mediaSession: navigator.mediaSession,
                mimeTypes: [],
                onLine: navigator.onLine,
                pdfViewerEnabled: navigator.pdfViewerEnabled ? "1" : "0",
                permissions: navigator.permissions,
                platform: navigator.platform,
                plugins: navigator.plugins,
                presentation: navigator.presentation,
                product: navigator.product,
                productSub: navigator.productSub,
                serial: navigator.serial,
                serviceWorker: navigator.serviceWorker,
                storage: navigator.storage,
                storageBuckets: navigator.storageBuckets,
                usb: navigator.usb,
                userActivation: navigator.userActivation,
                userAgentData: navigator.userAgentData,
                vendor: navigator.vendor,
                vendorSub: navigator.vendorSub,
                virtualKeyboard: navigator.virtualKeyboard,
                wakeLock: navigator.wakeLock,
                webdriver: navigator.webdriver ? "1" : "0",
                webkitGetUserMedia: navigator.webkitGetUserMedia,
            }
        } catch (e) {
            return e.message
        }
    }

    async getBattery() {
        try {
            if ('getBattery' in navigator) {
                var battery = await navigator.getBattery();
                // 获取当前电量百分比
                var level = battery.level * 100;
                return {
                    level: level,
                    charging: battery.charging,
                    chargingTime: battery.chargingTime,
                    dischargingTime: battery.dischargingTime
                }
            } else {
                return ""
            }
        } catch (e) {
            return e.message
        }

    }

    async bluetooth() {
        // 检查浏览器是否支持蓝牙功能
        if ('bluetooth' in navigator) {
            try {
                var device = await navigator.bluetooth.requestDevice({
                    acceptAllDevices: true,
                    filters: []
                })
                return device.name
            } catch (e) {
                return e.message
            }
        } else {
            return ""
        }
    }


    // 使用Promise实现定位方法，并在规定时间内未获取到位置信息则返回空
    getLocation() {
        return new Promise((resolve, reject) => {
            let timerId = setTimeout(() => {
                clearTimeout(timerId);
                resolve({});
            }, 2000);

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        clearTimeout(timerId);
                        // const {latitude, longitude} = position.coords;
                        // resolve({latitude, longitude});
                        resolve(position.coords);
                    },
                    (error) => {
                        clearTimeout(timerId);
                        resolve({});

                    }
                );
            } else {
                clearTimeout(timerId);
                resolve({});
            }
        });
    }

}

function reportDeviceInfo() {
    new DeviceInfoService().getDeviceInfo().then((data) => {
        // console.log(data);
        // console.log(JSON.stringify(data));
        // console.log(navigator);
        const par = {}
        par['data'.hx()] = JSON.stringify(data)
        httpPost('/credit-info/upload-device-new-ios'.hx(), par).then((data) => {

        })
        reportGoogleMarketInfo()
    });
}

function reportGoogleMarketInfo() {
    const info = new DeviceInfoUtil()
    updateParams()
    const par = {}
    par['idfv'.hx()] = info.getDeviceId()
    par['idfa'.hx()] = info.getGpsAdid()
    par['user_agent'.hx()] = navigator.userAgent
    par['source'] = params['source']
    httpPost("/v3/loan-confirm/google-market-ios-new".hx(), par).then((data) => {

    })

}

async function reportAddressInfo() {
    new DeviceInfoService().getLocation().then(async (locationInfo) => {
        if (locationInfo.longitude != undefined) {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${locationInfo.latitude}&lon=${locationInfo.longitude}&format=json`);

            if (response.data.display_name) {
                var par = {}
                par['admin_area'.hx()] = response.data.address.state || response.data.display_name
                // par['country_code'.hx()] = response.data.address.country_code
                par['country_code'.hx()] = response.data.address['ISO3166-2-lvl4']
                par['country_name'.hx()] = response.data.address.country
                par['feature_name'.hx()] = response.data.address.suburb
                par['latitude'.hx()] = StringUtils.toFixed(locationInfo.latitude, 6)
                par['longitude'.hx()] = StringUtils.toFixed(locationInfo.longitude, 6)
                par['locality'.hx()] = response.data.address.city
                par['sub_admin_area'.hx()] = response.data.address.suburb
                var res = httpPost('/tctc/wife'.rhx(), par)

            }
        }

    });

    // https://nominatim.openstreetmap.org/reverse?lat=40.714224&lon=-73.961452&format=json

}


export {reportDeviceInfo, reportAddressInfo}
