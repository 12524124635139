import styled from 'styled-components'

export const Wrapper = styled.div`
  left: 0;
  bottom: 0;
  width: 18.75rem;
  display: flex;
  flex-direction: column;

  .head {

    height: 5.5rem;
    line-height: 3.1rem;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    color: #FFFFFF;
    background: #22335D;
    margin-bottom: -2.5rem;
  }

  .head-content {

    margin-left: 0.75rem;
    width: 17.15rem;
    padding: 1.1rem;
    background: #FFFFFF;
    box-shadow: 0.1rem 0 1rem 0 rgba(24, 57, 107, 0.05);
    border-radius: 0.6rem;
    margin-bottom: 1.5rem;

    img {
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 1.1rem;
    }

    .name {
      font-size: 22px;
      font-weight: 600;
    }

    .phone {
      margin-top: 0.5rem;
      font-weight: 400;
      line-height: 0.7rem;
    }
  }

  .menu-item {
    margin-left: 0.75rem;
    margin-bottom: 0.8rem;
    width: 17.15rem;
    height: 3.2rem;
    background: #FFFFFF;
    box-shadow: 0 0.2rem 1rem 0 #DAD5FC;
    border-radius: 0.6rem;
    padding: 0.8rem;

    img {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.7rem;
    }

    .text {
      flex: 1;
      text-align: start;
      font-size: 14px;
      font-weight: 500;
    }

    .img-right {
      width: 0.65rem;
      height: 0.65rem;
    }
  }

`