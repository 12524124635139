import {useState} from "react";
import {httpPost} from "../../comm/http/http_request";
import {isLogin} from "../../comm/util/MyLocalStorage";
import commKeyDef from "../../comm/comm_key_def";
import Lget from "../../comm/lget/lget";
import {reportDeviceInfo} from "../../comm/service/device_info_service";

export default class HomeLogic {
    constructor({navigate}) {
        this.navigate = navigate;
        this.banners = [];
        this.largeCardData = null;
        this.smallCardData = null;
        this.productList = [];
        this.isLargeCard = false;
    }

    async getIndexData(loading) {
        const res = await httpPost('/v4/index/home-page'.hx(), {}, loading)
        this.setIndexData(res)
        return res
    }

    setIndexData(res) {
        let _indexData = res.data;
        if (_indexData != null) {
            for (let data of _indexData['list'.hx()]) {
                let key = data['type'.hx()];
                if (key === commKeyDef.banner) {
                    this.banners = data['item'.hx()]
                } else if (key === commKeyDef.largeCard) {
                    this.isLargeCard = true
                    this.largeCardData = data['item'.hx()][0]
                } else if (key === commKeyDef.smallCard) {
                    this.isLargeCard = false
                    this.smallCardData = data['item'.hx()][0]
                } else if (key === commKeyDef.productList) {
                    this.productList = data['item'.hx()]
                }
            }
        }
    }


    async clickApplyCall(item, navigate) {
        reportDeviceInfo()

        if (isLogin()) {
            // ReportedDataService().reportData(goToSetting: false);
//     BurialPointService.start(BPoint.productAuth, waitLocation: true)
// .then((value) => BurialPointService.end(BPoint.productAuth));
            var params = {}
            params['product_id'.hx()] = item['id'.hx()]
            let res = await httpPost(
                '/v3/product/apply'.hx(), params,
                'memuat...')
            if (res.success) {
                Lget.to(res.data['url'.hx()],{navigate:navigate})
                // var url =.formatRoute()
                // navigate(url)

            }
        } else {
            Lget.to(commKeyDef.login,{navigate:navigate})
        }

    }

}