import {Toast} from "antd-mobile";

export function ToastType(type, content, onClose) {
    let icon;
    if (type == 'Toast')
        type = "none"

    if (type == 'hide') {
        Toast.clear();
        return;
    }
    if (type == 'none') type = 'fail'

    let maskClickable = true;
    if (type === 'loading') {
        maskClickable = false;
        icon = type;
    }


    Toast.show({
        icon: icon,
        duration: 0,
        maskClickable: maskClickable,
        content: content,
    })
    // ToastInfo
//     if(type == 'Toast'){
//         return Toast.info(content,2,onClose);
//     }
//     if(type == 'loading'){
//         return Toast.loading(content,0);
//     }
//     if(type == 'success'){
//         return Toast.success(content, 2,onClose);
//     }
//     if(type == 'hide'){
//         return Toast.hide();
// }
//     if(type == 'fail'){
//         return Toast.fail(content);
//     }
}