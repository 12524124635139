import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {BrowserRouter} from 'react-router-dom'
import {ConfigProvider} from "antd-mobile";
import enUS from 'antd-mobile/es/locales/en-US'
import {
    FpjsProvider
} from '@fingerprintjs/fingerprintjs-pro-react'

// import  VConsole from "vconsole";
// const vConsole = new VConsole();
ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        <ConfigProvider locale={enUS}>
            <FpjsProvider
                loadOptions={{
                    apiKey: "aK7nnewi8mV6M774rdZU"
                }}
            >
                <App/>
            </FpjsProvider>
        </ConfigProvider>
    </BrowserRouter>
)