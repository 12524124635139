import styled from "styled-components";
export const Content = styled.div`
    left: 0;
    bottom: 0;
    background: linear-gradient( 180deg, #FFFFFF 0%, #F7F7F7 100%);

    .large-box {

        .large-top-bg {
            width: 18.75rem;
            height: 12.3rem;
            background-size: 100% 100%;
        }
        .large-card-bg{
            margin-top: 1.2rem;
            margin-bottom: 0.8rem;
            width: 16.85rem;
            height: 6.2rem;
            background-size: 100% 100%;


            .left-box{
                width: 5.4rem;
                justify-content: center;
                font-weight: 500;
                font-size: 0.7rem;
                color: #22335D;
                line-height: 0.8rem;
                img{
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-bottom: 0.3rem;
                    border-radius: 1.4rem;
                }
            }
            .right-box{
                flex: 1;
                margin-left: 0.6rem;
                justify-content: center;
                .t1{
                    font-weight: 400;
                    font-size: 0.7rem;
                    color: #22335D;
                    line-height: 0.7rem;
                }
                .t2{
                    font-weight: 500;
                    font-size: 1.5rem;
                    padding: 0 0.2rem;
                    color: #22335D;
                }
                .t3{
                    padding:0.2rem 0.65rem;
                    background: #FFF8CF;
                    border-radius: 0.65rem;
                }
            }
        }
        .btn-box{
            font-weight: 600;
            font-size: 0.8rem;
            color: #FFFFFF;
            width: 12.75rem;
            height: 2.4rem;
            line-height: 2.4rem;
            text-align: center;
            background: linear-gradient( 135deg, #572CD8 0%, #992CD8 100%);
            border-radius: 0.4rem;
        }
        .home_loanstep-box{
            width: 16.1rem;
            height: 7.85rem;
            
        }
    }

    .small-box {

        .small-top-bg {
            width: 18.75rem;
            height: 8.6rem;
            background-size: 100% 100%;
        }
        .small-card-bg{
            margin-top: 1.2rem;
            margin-bottom: 0.8rem;
            width: 16.85rem;
            height: 6.2rem;
            background-size: 100% 100%;


            .left-box{
                width: 5.4rem;
                justify-content: center;
                font-weight: 500;
                font-size: 0.7rem;
                color: #22335D;
                line-height: 0.8rem;
                img{
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-bottom: 0.3rem;
                    border-radius: 0.2rem;
                    
                }
            }
            .right-box{
                flex: 1;
                margin-left: 0.6rem;
                justify-content: center;
                .t1{
                    font-weight: 400;
                    font-size: 0.7rem;
                    color: #22335D;
                    line-height: 0.7rem;
                }
                .t2{
                    font-weight: 500;
                    font-size: 1.5rem;
                    padding: 0 0.2rem;
                    color: #22335D;
                }
                .t3{
                    padding:0.2rem 0.65rem;
                    background: #FFF8CF;
                    border-radius: 0.65rem;
                }
            }
        }
        .btn-box{
            font-weight: 600;
            font-size: 0.8rem;
            color: #FFFFFF;
            width: 12.75rem;
            height: 2.4rem;
            line-height: 2.4rem;
            text-align: center;
            background: linear-gradient( 135deg, #572CD8 0%, #992CD8 100%);
            border-radius: 0.4rem;
        }

        .product-logo {
            width: 2rem;
            height: 2rem;
        }

        .product-name {
            margin: 0.75rem 0;
            font-size: 1rem;
            font-weight: 600;
            color: #1A066F;
            line-height: 1rem;

        }

        .tag-item {
            font-size: 0.6rem;
            font-weight: 600;
            color: #1A066F;
            line-height: 0.85rem;

        }

        .product-amount {
            margin-top: 1.25rem;
            font-size: 1.85rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1A066F;
            line-height: 1.3rem;
        }

        .product-btn {
            margin-top: 1.5rem;
            width: 14.8rem;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            background: #1A066F;
            border-radius: 10px;
            font-size: 0.9rem;
            font-weight: 600;
            color: #FFFFFF;

        }
    }

    .product-item {
        margin-top: 1rem;
        width: 16.75rem;
        height: 4.1rem;
        background: #F5F1FF;
        border-radius: 0.6rem;


      .left-box{
          width: 4.6rem;
          img {
              margin-top: 0.2rem;
              
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 0.2rem;
              
          }

          .product-name {
              margin-top: 0.2rem;
              flex: 1;
              height: 1rem;
              font-weight: 500;
              font-size: 0.6rem;
              color: #41465C;
              line-height: 0.8rem;
          }
      }
        .line{
            width: 0.1rem;
            height: 2.65rem;
            border: 0.05rem solid #D4C6F6;
        }

        
        .right-box{
            flex: 1;
            padding-left: 0.55rem;
            .amountRangeDes {
                font-weight: 400;
                font-size: 0.7rem;
                color: #000004;
                line-height: 0.7rem;
            }
            .amount {
                margin-top: 0.4rem;
                font-weight: 500;
                font-size: 1.1rem;
                color: #000004;
                line-height: 1.1rem;
            }
        }
        .right-img{
            width: 2.45rem;
            height: 4.1rem;
            
        }
       

        .tag-item {
            height: 0.85rem;
            font-size: 0.6rem;
            font-weight: 600;
            color: #1A066F;
            line-height: 0.85rem;
        }
    }
    
    
    .order-tab-box{
        width: 100%;
        margin-top: 1.2rem;
        margin-bottom: 1rem;
        .item-box{
            flex: 1;
            img{
                width: 2.3rem;
                height: 2.3rem;
                margin-bottom: 0.4rem;
            }
            span{
                
            }
        }
    }
`