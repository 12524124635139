import styled from 'styled-components'

export const HeaderWrapper = styled.div`
 .head{
     height: 3rem;
     line-height: 3.1rem;
     text-align: center;
     font-size: 0.8rem;
     font-weight: 600;
     color: #FFFFFF;
     background: #1A066F;
     width: 18.75rem;

     .back-img {
         position: fixed;
         top: 1rem;
         left: 1rem;
         width: 1.2rem;
         height: 1.2rem;
     }

 }
`