const hxm = require('../key_mix.json');

export function isEmpty(str) {
    if (str === undefined || str == null || str.length === 0) return true;
    return false;
}

export function toFixed(value, decimals = 2) {
    if (isEmpty(value)) return "0";
    // 判断参数是否为字符串，如果是则尝试转换为数字类型
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    // 判断参数是否为数字类型
    if (typeof value !== 'number' || isNaN(value)) {
        throw new Error('Invalid input: value must be a number or a numeric string');
    }

    // 使用toFixed方法保留指定位数的小数
    return value.toFixed(decimals);
}

export function valueDefault(str, defaultStr) {
    if (isEmpty(str)) return defaultStr
    return str;
}

/*
 * 去掉字符串前后空格
 */
String.prototype.trim = function () {
    return this.replace(/(^\s*)|(\s*$)/g, "");
}


/*
 * 把字符串转换为人民币的显示方式(如：￥123,456,78.12)
 * @param decimalCount - 保留小数的位数
 */
String.prototype.toMoneyString = function (decimalCount) {
    var result = "";
    var arr = this.split('.');
    var i;
    for (i = arr[0].length - 3; i > 0; i -= 3) {
        result = "," + arr[0].substr(i, 3) + result;
    }
    result = "￥" + arr[0].substring(0, i + 3) + result;
    if (arr.length > 1) {

        if (decimalCount > arr[1].length) {
            result += "." + arr[1];
            for (var j = 0; j < decimalCount - arr[1].length; j++) {
                result += "0";
            }
        } else {
            result += "." + arr[1].substring(0, decimalCount);
        }
    }
    return result;
}


/*
 * 日期大小验证
 * @param minDate - 日期允许的最小值 - null表示不限制日期的最小值(参数类型可以是字符串也可以是日期)
 * @param maxValue - 日期允许的最大值 - null表示不限制日期的最大值 (参数类型可以是字符串也可以是日期)
 * @return 验证成功返回true，失败返回false
 */
String.prototype.compareDate = function (minValue, maxValue) {
    var dateValue = this.toDate();
    if (minValue != null) {
        if (typeof minValue == "string") {
            minValue = minValue.toDate();
        }
        if (dateValue < minValue) {
            return false;
        }
    }
    if (maxValue != null) {
        if (typeof maxValue == "string") {
            maxValue = maxValue.toDate();
        }
        if (dateValue > maxValue) {
            return false;
        }
    }
    return true;
}

String.prototype.hx = function () {
    var value = hxm[this];
    if (value === undefined) return this;
    return value;
}
String.prototype.rhx = function () {
    var value = this;
    return value;
};


Map.prototype.query = function (newQuery) {
    if (newQuery !== undefined) {
        let sStr = newQuery,
            params = {};
        let pattern = /(\w+)=([^&#]*)*/g;
        let arr = sStr.match(pattern);
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                let kv = arr[i].split('=');
                this[kv[0]] = kv[1];
            }
        }
    }
//-----------------------------------------------------
    const queryParams = [];
    for (const key in this) {
        if (this.hasOwnProperty(key)) {
            const value = newQuery[key];
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    }
    return queryParams.join('&');
}
