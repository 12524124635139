import {v4 as uuidv4} from 'uuid';
import AppConfig from '../../env.json'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {isEmpty} from "./string-extension";

let gpsAdid = ""

class DeviceInfoUtil {
    /**
     * 生成唯一uuid，缓存在本地localStorage(本地文件) 用户清除浏览器缓存或者使用隐私模式访问网站时，localStorage 中的数据可能会被清除或不可用。
     */
    getDeviceId() {
        let deviceId = localStorage.getItem('myUniqueUUID');
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem('myUniqueUUID', deviceId);
        }
        return deviceId;
    }


    getAppVersion() {
        return AppConfig.appVersion
    }

    getDeviceName() {
        const userAgent = navigator.userAgent;
        if (/iPhone/i.test(userAgent)) {
            return 'iPhone';
        } else if (/iPad/i.test(userAgent)) {
            return 'iPad';
        } else if (/Android/i.test(userAgent)) {
            return 'Android Device';
        } else {
            return 'Unknown Device';
        }


    }


    /**
     *浏览器版本，从userAgent中截取
     */
    getOsVersion() {
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            // Android 系统
            const androidVersion = userAgent.match(/Android\s([\d\.]+)/);
            return androidVersion ? androidVersion[1] : 'Unknown';
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            // iOS 系统
            const iosVersion = userAgent.match(/OS\s(\d+[_.]\d+(?:[_.]\d+)?)/);
            return iosVersion ? iosVersion[1].replace(/_/g, '.') : 'Unknown';
        } else {
            return 'Unknown';
        }
    }

    getGpsAdid() {
        if (!isEmpty(gpsAdid)) {
            return gpsAdid
        }
        let adid = localStorage.getItem('generateFingerprint');
        gpsAdid = adid
        return gpsAdid || "";
    }

    async gpsAdidInit() {
        console.log("开始获取指纹")
        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get()
        console.log("获取指纹：" + result.visitorId)
        localStorage.setItem('generateFingerprint', result.visitorId);
        return result.visitorId
    }
}

export {DeviceInfoUtil}