import {httpPost, params} from '../http/http_request'
import * as StringUtils from "../util/string-extension";
import {Toast} from "antd-mobile";
import {ToastType} from "../util/toastList";
import {DeviceInfoUtil} from "../util/device_info_util";

const BPoint = {
    register: 1,
    idPan: 2,
    idFront: 2,
    // idBack: 4,
    face: 3,
    personage: 4,
    job: 5,
    contact: 6,
    bank: 7,
    startApply: 8,
    endApply: 9,
    productAuth: 1,
    questionnaire: 1,
}
var isInit = false

class BurialPointService {
    static  bpMap = {};
    //-1 不能定位 ，0未定位 ，1 定位中，2 成功
    static locatable = 0

    static init() {
        if (isInit) return;
        isInit = true
        if (navigator.geolocation) {
            BurialPointService.locatable = 1;//不能定位
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    BurialPointService.locatable = 2
                    // ToastType('success', '定位成功！')

                },
                (error) => {
                    ToastType('success', error.message)
                    BurialPointService.locatable = -1
                }
            );
        } else {
            BurialPointService.locatable = -1;//不能定位
        }
    }

    constructor() {
    }

    static async start(bPoint, {ache, waitLocation = false}) {

        await new BurialPointService()
            .pointStart(bPoint, {
                ache: ache, waitLocation: waitLocation
            });
    }

    static async end(bPoint, {ache, productId}) {
        await new BurialPointService().pointEnd(bPoint, {
            ache: ache, productId: productId
        });
    }

    async pointStart(teach, {ache, productId, waitLocation = false}) {
        let startTime = Math.floor(new Date().getTime() / 1000);
        let map = await this.getParams(teach, {
            waitLocation: waitLocation
        });
        // map['start_time'.hx] = startTime;
        map['start_time'.hx()] = startTime;
        map['source_order_no'.hx()] = ache;
        BurialPointService.bpMap[teach] = map;
    }

    async pointEnd(teach, {ache, productId}) {
        let map = BurialPointService.bpMap[teach];
        map ??= await this.getParams(teach, {
            isLocation: false
        });
        // map['source_order_no'.hx] = ache;
        map['source_order_no'.hx()] = ache;
        map['productId'.hx()] = productId;
        map['end_time'.hx()] = Math.floor(new Date().getTime() / 1000);

        if (teach === BurialPointService.bpMap[BPoint.startApply] || teach === BurialPointService.bpMap[BPoint.endApply]) {
            // map['start_time'.hx] = map['end_time'.hx];
            map['start_time'] = map['end_time'];
        }

        if (StringUtils.isEmpty(map['longitude'])) {
            let locationInfo = await this.getLocation()
            if (locationInfo != null) {
                map['longitude'.hx()] = StringUtils.toFixed(locationInfo.longitude, 6);
                map['latitude'.hx()] = StringUtils.toFixed(locationInfo.latitude, 6);
            }
        }

        await this.pointRequest(teach);
    }


// 使用Promise实现定位方法，并在规定时间内未获取到位置信息则返回空
    getLocation() {
        return new Promise((resolve, reject) => {
            let timerId = setTimeout(() => {
                clearTimeout(timerId);
                resolve({});
            }, 2000);

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        clearTimeout(timerId);
                        // const {latitude, longitude} = position.coords;
                        // resolve({latitude, longitude});
                        resolve(position.coords);
                    },
                    (error) => {
                        clearTimeout(timerId);
                        resolve({});

                    }
                );
            } else {
                clearTimeout(timerId);
                resolve({});
            }
        });
    }

    async getParams(teach, {isLocation = true, waitLocation = false}) {
        let map = {};
        map['sceneType'.hx()] = teach;
        map['client_type'.hx()] = 3

        // //android
        // if (Platform.isAndroid) {
        //     map['equipment_a'.hx] = Platform.isAndroid
        //         ? await DeviceDataPlugin.getAndroidId
        //         : await DeviceDataPlugin.getIdfv; //1安卓传and_id ios传idfv
        //     map['equipment_b'.hx] = Platform.isAndroid
        //         ? await DeviceDataPlugin.getGdid
        //         : await DeviceDataPlugin.getIdfa; //	安卓传gaid ios传idfa
        // } else {
        //     //ios
        //     map['deviceNo'.hx] = await DeviceDataPlugin.getIdfv; //1安卓传and_id ios传idfv
        // }
        var info = new DeviceInfoUtil()
        map['equipment_a'.hx()] = info.getDeviceId(); //1安卓传and_id ios传idfv
        map['equipment_b'.hx()] = info.getGpsAdid(); //1安卓传and_id ios传idfa
        if (isLocation) {
            // if (waitLocation) {
            //     LocationInfo? locationInfo =
            //         await LocationManage.instance.determinePosition();
            //     if (locationInfo != null) {
            //         map['longitude'.hx] = StringUtils.toFixed(locationInfo.longitude, 6);
            //         map['latitude'.hx] = StringUtils.toFixed(locationInfo.latitude, 6);
            //     }
            // } else {
            //     LocationManage.instance.determinePosition().then((locationInfo) {
            //         if (locationInfo != null) {
            //             map['longitude'.hx] =
            //                 StringUtils.toFixed(locationInfo.longitude, 6);
            //             map['latitude'.hx] = StringUtils.toFixed(locationInfo.latitude, 6);
            //         }
            //     });
            // }
        }
        return map;
    }

    async pointRequest(teach) {
        let map = BurialPointService.bpMap[teach];
        if (map == null) return;
        // String action = '/v3/buried-point/upload-new'.hx;
        let action = '/v3/buried-point/upload-new'.hx();
        let r = await httpPost(action, map)
        if (r.success) {
        } else {
            // PrintUtils.p(r.msg);
        }
        // ToastType('success', JSON.stringify(map))
        console.log(JSON.stringify(map))
    }


    isAndroid() {
        return /Android/i.test(navigator.userAgent);
    }

    function

    isIOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

}

export {BurialPointService, BPoint}