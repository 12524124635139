import {$httpForm, Qs} from '../util/tools';
import qs from "qs";
import {ToastType} from "../util/toastList";
import {getToken} from '../util/MyLocalStorage'
import {DeviceInfoUtil} from "../util/device_info_util";
import {isEmpty} from "../util/string-extension";

const post = "http://147.139.212.164:8657";
const baseUrl = "/windy";
var params = Qs();

// 印度计算器
// Super Credit
// 接口：http://147.139.212.164:8657/windy/
//     文档：https://doc.alta-dg.mx/in_super_credit_ios/
const toQueryStr = function (obj) {
    const queryParams = [];
    const info = new DeviceInfoUtil()
    if (isEmpty(obj['deviceId'.hx()])) {
        obj['deviceId'.hx()] = info.getDeviceId()
    }
    if (isEmpty(obj['appVersion'.hx()])) {
        obj['appVersion'.hx()] = info.getAppVersion()
    }
    if (isEmpty(obj['deviceName'.hx()])) {
        obj['deviceName'.hx()] = info.getDeviceName()
    }

    if (isEmpty(obj['osVersion'.hx()])) {
        obj['osVersion'.hx()] = info.getOsVersion()
    }

    if (isEmpty(obj['gps_adid'.hx()])) {
        obj['gps_adid'.hx()] = info.getGpsAdid()
    }


    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    }
    return queryParams.join('&');
}

const httpPost = (url, _params, loadingStr) => new Promise((resolve, reject) => {
    const showLoading = loadingStr != null && loadingStr.length !== 0;
    if (showLoading) ToastType('loading', loadingStr);
    updateParams()
    params.sessionId = getToken()
    let _url = `${baseUrl}${url}?${toQueryStr(params)}`;
    $httpForm.post(_url, qs.stringify(_params))
        .then(res => {
            var currentTimestamp = Date.now(); // 获取当前时间戳（毫秒）
            if (showLoading) ToastType('hide');
            var result = responseAnalysis(res)
            if (!result.success) ToastType('Toast', result.message);
            resolve(result)
        }).catch(error => {
        ToastType('fail', error.message);
        resolve(null)
    });
});
const httpFile = (url, formData, loadingStr) => new Promise((resolve, reject) => {
    const showLoading = loadingStr != null && loadingStr.length !== 0;
    if (showLoading) ToastType('loading', loadingStr);
    updateParams()
    params.sessionId = getToken()
    let _url = `${baseUrl}${url}?${toQueryStr(params)}`;
    $httpForm.post(_url, formData)
        .then(res => {
            if (showLoading) ToastType('hide');
            var result = responseAnalysis(res)
            if (!result.success) {
                ToastType('Toast', result.message);
            }
            resolve(result)
        }).catch(error => {
        ToastType('fail', error.message);
        resolve(null)
    });
});
const httpGet = (url, _params, loadingStr, isToast = true) => new Promise((resolve, reject) => {
    const showLoading = loadingStr != null && loadingStr.length !== 0;
    if (showLoading) ToastType('loading', loadingStr);
    updateParams()
    $httpForm.get(`${baseUrl}${url}?${toQueryStr(params)}&${toQueryStr(_params)}`, {})
        .then(res => {
            if (showLoading) ToastType('hide');
            var result = responseAnalysis(res)
            if (!result.success) ToastType('Toast', result.message);
            resolve(result)
        })
});

const responseAnalysis = (res) => {
    let code = res['code'.hx()]
    if (code == undefined)
        code = res['code']
    let data = res['data'.hx()] || res['data']
    let message = res['message'.hx()] || res['message']

    return {
        'success': parseInt(code) === 0,
        "code": code,
        "message": message,
        "data": data,
        "res": res
    }
}
const updateParams = () => {
    params = Qs();
}
export {httpPost, httpGet, httpFile, params, toQueryStr, updateParams,}